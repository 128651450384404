import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import Icon from "./Icon";
import { Colors, globalStyles, Typography } from "../global";
import AwesomeAlert from "react-native-awesome-alerts";
import { widthPercentageToDP as wpd } from "react-native-responsive-screen";
import { androidSubText, iOSSubText } from "./../global";

const iconSize = Platform.OS == "web" ? wpd(4) : 50;
const exclamationSize = Platform.OS == "web" ? wpd(0.9) : 12;
const iconContainerPadding = Platform.OS == "web" ? wpd(0.5) : 4;

export default function ExpandableCardIcon({ certificate, major }) {
  const [clicked, setClicked] = useState(false);
  const [details, setDetails] = useState("");
  const icons = certificate
    ? [
        {
          iconName: "certification_selection_test_duration",
          iconLabel: certificate.get("test_duration"),
          iconDetails: certificate.get("test_duration_details"),
        },
        {
          iconName:
            certificate.get("test_method") == "PaperBased"
              ? "certification_selection_test_method_paper"
              : "certification_selection_test_method",
          iconLabel: certificate.get("test_method_label"),
          iconDetails: certificate.get("test_method_details"),
        },
        {
          iconName: "certification_selection_cost",
          iconLabel: certificate.get("cost_label"),
          iconDetails: certificate.get("cost_details"),
        },
        {
          iconName: "certification_selection_test_validity",
          iconLabel: certificate.get("test_validity_label"),
          iconDetails: certificate.get("test_validity_details"),
        },
        {
          iconName: "certification_selection_max_score",
          iconLabel: certificate.get("max_score_label"),
          iconDetails: certificate.get("max_score_details"),
        },
        {
          iconName: "certification_selection_listening",
          iconLabel: certificate.get("listening_label"),
          iconDetails: certificate.get("listening_details"),
        },
        {
          iconName: "certification_selection_reading",
          iconLabel: certificate.get("reading_label"),
          iconDetails: certificate.get("reading_details"),
        },
        {
          iconName: "certification_selection_speaking",
          iconLabel: certificate.get("speaking_label"),
          iconDetails: certificate.get("speaking_details"),
        },
        {
          iconName: "certification_selection_writing",
          iconLabel: certificate.get("writing_label"),
          iconDetails: certificate.get("writing_details"),
        },
      ]
    : [
        {
          iconName: "major_home_page_credit_hours",
          iconLabel: major.credit_hours_label ?? "None",
          iconDetails: major.credit_hours_details,
        },
        {
          iconName: "major_home_page_instruction_language",
          iconLabel: major.instruction_language_label ?? "None",
          iconDetails: major.instruction_language_details,
        },
        {
          iconName: "major_home_page_avg_cost_per_year",
          iconLabel: major.avg_cost_per_year_label ?? "None",
          iconDetails: major.avg_cost_per_year_details,
        },
        {
          iconName: "major_home_page_founding_year_semester",
          iconLabel: major.founding_year_semester ?? "None",
          iconDetails: major.founding_year_semester_details,
        },
        {
          iconName: "major_home_page_internship",
          iconLabel: major.internship_label ?? "None",
          iconDetails: major.internship_details,
        },
        {
          iconName: "major_home_page_final_year_project",
          iconLabel: major.final_year_project_label ?? "None",
          iconDetails: major.final_year_project_details,
        },
        {
          iconName: "major_home_page_acceptance_rate",
          iconLabel: major.acceptance_rate ?? "None",
          iconDetails: major.acceptance_rate_details,
        },
        {
          iconName: "major_home_page_academic_accredation_body",
          iconLabel: major.academic_accreditation_body ?? "None",
          iconDetails: major.academic_accreditation_body_details,
        },
        {
          iconName: "major_home_page_major_gender",
          iconLabel: major.gender_label ?? "None",
          iconDetails: major.gender_details,
        },
        {
          iconName: "major_home_page_college_name",
          iconLabel: major.college_name ?? "None",
          iconDetails: major.college_name_details,
        },
        {
          iconName: "major_home_page_degree_name",
          iconLabel: major.degree_title ?? "None",
          iconDetails: major.degree_title_details,
        },
      ];

  return (
    <>
      {clicked && (
        <AwesomeAlert
          show={clicked}
          message={details}
          closeOnTouchOutside
          onDismiss={() => {
            setClicked(false);
          }}
          alertContainerStyle={{ zIndex: 1 }}
          contentContainerStyle={{ backgroundColor: "transparent" }}
          contentStyle={[globalStyles.dropShadow, localStyles.ModalContainer]}
          messageStyle={[Typography.arabicText, { color: Colors.white }]}
          overlayStyle={{ backgroundColor: "transparent" }}
        />
      )}

      {icons?.map((icon, indx) =>
        icon.iconLabel != "None" ? (
          icon.iconDetails != undefined ? (
            <TouchableOpacity
              key={indx}
              onPress={() => {
                setClicked(true);
                setDetails(icon.iconDetails);
              }}
              style={localStyles.iconContainer}
            >
              <Icon icon={icon.iconName} size={iconSize} />

              <Text style={[Typography.arabicText, localStyles.label]}>
                {icon.iconLabel}&nbsp;
                <FontAwesome
                  name="exclamation-circle"
                  size={exclamationSize}
                  color="grey"
                />
              </Text>
            </TouchableOpacity>
          ) : (
            <View key={indx} style={localStyles.iconContainer}>
              <Icon icon={icon.iconName} size={iconSize} />

              <Text style={[Typography.arabicText, localStyles.label]}>
                {icon.iconLabel}
              </Text>
            </View>
          )
        ) : null
      )}
    </>
  );
}

const localStyles = StyleSheet.create({
  label: {
    textAlign: "center",
    ...Platform.select({
      ios: {
        fontSize: iOSSubText,
      },
      android: {
        fontSize: androidSubText,
      },
    }),
  },
  iconContainer: {
    alignItems: "center",
    flexBasis: "25%",
    padding: iconContainerPadding,
    ...Platform.select({
      web: {
        flexBasis: "14.2857143%",
      },
    }),
  },
  ModalContainer: {
    backgroundColor: Colors.grey,
    borderRadius: wpd(1),
    padding: "2%",
    margin: "5%",
    justifyContent: "center",
    ...Platform.select({
      web: {
        alignSelf: "center",
        width: wpd(40),
        padding: wpd(2.5),
      },
    }),
  },
  ModalCloseIcon: {
    flexDirection: "row",
  },
  ModalText: {
    ...Typography.arabicText,
    color: Colors.white,
    textAlign: "center",
    paddingBottom: "5%",
  },
});
