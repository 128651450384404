import React, { useEffect, useRef } from "react";
import {
  Animated,
  Image, StyleSheet,
  TouchableOpacity, View
} from "react-native";
import Icon from "../components/Icon";

const ButtonD = require(`../assets/icons/fab_not_selected.png`);
const ButtonDSelected = require(`../assets/icons/fab_selected.png`);

export default function FAB({ navigationRef, active, setActive }) {
  const mode = useRef(new Animated.Value(0));
  const buttonSize = useRef(new Animated.Value(1));

  //Ensures that state and animation are always in sync
  useEffect(() => {
    if (active) handleOpen();
    if (!active) handleClose();
  }, [active]);

  const handleAnimation = () => {
    active ? handleClose() : handleOpen();
  };

  const handleClose = () => {
    Animated.timing(mode.current).stop();
    Animated.timing(buttonSize.current).stop();
    setActive(false);
    Animated.parallel([
      Animated.timing(buttonSize.current, {
        toValue: 0.95,
        duration: 100,
        useNativeDriver: false,
      }),
      Animated.sequence([
        Animated.timing(mode.current, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(buttonSize.current, {
          toValue: 1,
          duration: 100,
          useNativeDriver: false,
        }),
      ]),
    ]).start();
  };

  const handleOpen = () => {
    Animated.timing(mode.current).stop();
    Animated.timing(buttonSize.current).stop();
    setActive(true);
    Animated.parallel([
      Animated.timing(buttonSize.current, {
        toValue: 0.95,
        duration: 100,
        useNativeDriver: false,
      }),
      Animated.sequence([
        Animated.timing(mode.current, {
          toValue: 1,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(buttonSize.current, {
          toValue: 1,
          duration: 100,
          useNativeDriver: false,
        }),
      ]),
    ]).start();
  };

  const homeY = mode.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -335],
  });

  const servicesY = mode.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -280],
  });

  const uniSelectY = mode.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -225],
  });

  const searchY = mode.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -170],
  });

  const favouriteY = mode.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -115],
  });

  const chatY = mode.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -60],
  });

  const sizeStyle = {
    transform: [{ scale: buttonSize.current }],
  };

  const goto = (route) => {
    route == "Home" ? setActive(false) : handleAnimation();
    navigationRef.current.navigate(route);
  };

  return (
    <View
      style={{
        position: "absolute",
        alignItems: "center",
        left: "50%",
        bottom: "13%",
      }}
    >
      <Animated.View
        style={{ position: "absolute", top: homeY, opacity: mode.current }}
      >
        <TouchableOpacity
          onPress={() => {
            goto("Home");
          }}
          style={localStyles.secondaryButton}
        >
          <Icon icon="fab_home_page" size={36} />
        </TouchableOpacity>
      </Animated.View>

      <Animated.View
        style={{ position: "absolute", top: servicesY, opacity: mode.current }}
      >
        <TouchableOpacity
          onPress={() => {
            goto("Our Services");
          }}
          style={localStyles.secondaryButton}
        >
          <Icon icon="fab_about_us" size={36} />
        </TouchableOpacity>
      </Animated.View>

      <Animated.View
        style={{ position: "absolute", top: uniSelectY, opacity: mode.current }}
      >
        <TouchableOpacity
          onPress={() => {
            goto("University Selection");
          }}
          style={localStyles.secondaryButton}
        >
          <Icon icon="fab_university_selection" size={36} />
        </TouchableOpacity>
      </Animated.View>

      <Animated.View
        style={{ position: "absolute", top: searchY, opacity: mode.current }}
      >
        <TouchableOpacity
          onPress={() => {
            goto("Majors Selection");
            handleAnimation();
          }}
          style={localStyles.secondaryButton}
        >
          <Icon icon="fab_major_selection" size={36} />
        </TouchableOpacity>
      </Animated.View>

      <Animated.View
        style={{ position: "absolute", top: favouriteY, opacity: mode.current }}
      >
        <TouchableOpacity
          onPress={() => {
            goto("Favourite");
          }}
          style={localStyles.secondaryButton}
        >
          <Icon icon="fab_favourites" size={36} />
        </TouchableOpacity>
      </Animated.View>

      <Animated.View
        style={{ position: "absolute", top: chatY, opacity: mode.current }}
      >
        <TouchableOpacity
          onPress={() => {
            goto("Feedback");
          }}
          style={localStyles.secondaryButton}
        >
          <Icon icon="fab_feedback" size={36} />
        </TouchableOpacity>
      </Animated.View>

      <Animated.View style={[localStyles.button, sizeStyle]}>
        <TouchableOpacity onPress={handleAnimation} style={localStyles.button}>
          <Image
            source={active ? ButtonDSelected : ButtonD}
            style={{ width: "100%", height: "100%" }}
          />
        </TouchableOpacity>
      </Animated.View>
    </View>
  );
}

const localStyles = StyleSheet.create({
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: 62,
    height: 62,
    borderRadius: 36,
    position: "absolute",
  },
  secondaryButton: {
    width: 38,
    height: 38,
    borderRadius: 24,
    backgroundColor: "white",
    borderColor: "#700016",
    borderWidth: 1,
  },
});
