import React, { Suspense, useEffect, useRef, useState } from "react";
import { isBrowser } from "react-device-detect";
import { Helmet } from "react-helmet";
import {
  Animated,
  Dimensions,
  I18nManager,
  Keyboard,
  Platform,
  Pressable,
  StyleSheet,
} from "react-native";
import { Context } from "./components/Context.js";
import FAB from "./components/FAB";
import Header_web from "./components/header_web";
import { LoadingFallback } from "./components/LoadingFallback.js";
import { useLoadedAssets } from "./hooks/useLoadedAssets.js";
import Navigator from "./navigation/Navigator";
import MobileBrowserScreen from "./screens/mobileBrowserScreen.js";

export default function App() {
  const [isUni, setIsUni] = useState(false);
  I18nManager.allowRTL(true);
  I18nManager.forceRTL(true);
  I18nManager.swapLeftAndRightInRTL(true);
  const isLoadingComplete = useLoadedAssets();
  const navigationRef = useRef(null);

  const routeNameRef = useRef(null);

  const Overlay = Animated.createAnimatedComponent(Pressable);
  const overlayOpacity = useRef(new Animated.Value(0));
  const [showOverlay, setShowOverlay] = useState(false);
  const [showFab, setShowFab] = useState(false);
  const [fabActive, setFabActive] = useState(false);

  const [selectedBtn, setSelectedBtn] = useState(null);

  const openFab = () => {
    setShowOverlay(true);
    setFabActive(true);
    Animated.timing(overlayOpacity.current).stop();
    Animated.timing(overlayOpacity.current, {
      toValue: 0.5,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  const closeFab = () => {
    setFabActive(false);
    Animated.timing(overlayOpacity.current).stop();
    Animated.timing(overlayOpacity.current, {
      toValue: 0.05,
      duration: 200,
      useNativeDriver: true,
    }).start((state) => state.finished && setShowOverlay(false));
  };

  const setFabActiveState = (active) => {
    active ? openFab() : closeFab();
  };

  const handleNav = (e) => {
    //Hides FAB on Home screen
    if (Platform.OS == "web") return;
    const navState = e.data.state;
    const currentScreen = navState.routes[navState.index].name;

    setShowFab(currentScreen != "Home");
  };

  const handleNavWeb = (e) => {
    const navState = e.data.state;
    const currentScreen = navState.routes[navState.index].name;
    setSelectedBtn(currentScreen);
    // console.log("current screen", currentScreen);
  };

  const handleBack = (e) => {
    //Pressing back when fab is active will minimize FAB
    if (e.data.action.type == "GO_BACK" && fabActive) {
      e.preventDefault();
      closeFab();
    }
  };

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        setShowFab(false);
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setShowFab(true);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else if (Platform.OS == "web") {
    if (isBrowser) {
      return (
        <Context.Provider value={[isUni, setIsUni]}>
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossorigin
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&family=Cairo:wght@400;700;900&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <Header_web
            navigationRef={navigationRef}
            selectedBtn={selectedBtn}
            setSelectedBtn={setSelectedBtn}
          />
          <Suspense fallback={<LoadingFallback />}>
            <Navigator navigationRef={navigationRef} handleNav={handleNavWeb} />
          </Suspense>
        </Context.Provider>
      );
    } else {
      return (
        <>
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossorigin
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&family=Cairo:wght@400;700;900&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <MobileBrowserScreen />
        </>
      );
    }
  } else {
    return (
      <>
        {/* <StatusBar style={"dark"} /> */}
        <Navigator
          navigationRef={navigationRef}
          handleNav={handleNav}
          handleBack={handleBack}
          routeNameRef={routeNameRef}
        />
        {showOverlay && (
          <Overlay
            style={[styles.overlay, { opacity: overlayOpacity.current }]}
            onPress={closeFab}
          />
        )}
        {showFab && (
          <FAB
            navigationRef={navigationRef}
            active={fabActive}
            setActive={setFabActiveState}
          />
        )}
      </>
    );
  }
}

const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  overlay: {
    position: "absolute",
    left: 0,
    top: 0,
    height: Dimensions.get("screen").height,
    width: Dimensions.get("screen").width,
    backgroundColor: "black",
  },
});
