import { AntDesign } from "@expo/vector-icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import {
  heightPercentageToDP as hpd,
  widthPercentageToDP as wpd,
} from "react-native-responsive-screen";
import { Context } from "../components/Context.js";
import { Colors, webTextPercent } from "../global";
import strings from "./../assets/strings/hardCodedStrings.json";

function useOutsideClick(ref, setOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
export default function Header_web({
  navigationRef,

  route,
  selectedBtn,
  setSelectedBtn,
}) {
  const [isUni, setIsUni] = useContext(Context);
  //console.log("value is", context);
  //const [selectedBtn, setSelectedBtn] = useState(null);
  const goTo = (dest) => navigationRef.current.navigate(dest);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setOpen);

  const [value, setValue] = useState(null);
  const [items, setItems] = useState([
    { label: strings.navbar.navbar_know_us_about_us, value: "aboutUs" },
    {
      label: strings.navbar.navbar_know_us_our_services,
      value: "ourServices",
    },
  ]);
  // TODO : need to implement linking  in this useEffect //
  useEffect(() => {
    if (value == "ourServices") {
      setSelectedBtn("Our Services");

      goTo("Our Services");
    }
    if (value == "aboutUs") {
      setSelectedBtn("About Us");
      goTo("About Us");
    }
    setValue(null);
  }, [value]);
  return (
    <View style={localStyles.mainView}>
      <View style={{ flexDirection: "row" }}>
        <TouchableOpacity
          onPress={(event) => {
            event.preventDefault();
            setSelectedBtn("Home");
            goTo("Home");
          }}
          accessibilityRole="link"
          href="/"
        >
          <Image
            style={{
              height: wpd(2),
              width: wpd(2),
              resizeMode: "contain",
              marginVertical: hpd(1),
              marginHorizontal: wpd(2.5),
            }}
            source={require("../assets/icons/nav_bar_white_logo-01.png")}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={(event) => {
            event.preventDefault();
            goTo("Main Filter");
          }}
          accessibilityRole="link"
          href="/mainFilter"
        >
          <AntDesign
            name="search1"
            size={wpd(2)}
            color={Colors.white}
            style={{ paddingVertical: hpd(1), paddingHorizontal: wpd(2.5) }}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={(event) => {
            event.preventDefault();
            setSelectedBtn("Home");
            goTo("Home");
          }}
          accessibilityRole="link"
          href="/"
          style={localStyles.button}
        >
          <Text
            style={[
              localStyles.buttonText,
              {
                color:
                  selectedBtn === "Home" || selectedBtn === "Main Filter"
                    ? Colors.burgundy
                    : Colors.white,
              },
            ]}
          >
            {strings.navbar.navbar_homepage}
          </Text>
        </TouchableOpacity>
        <View ref={wrapperRef}>
          <DropDownPicker
            closeAfterSelecting={true}
            containerStyle={[localStyles.button]}
            dropDownContainerStyle={{
              backgroundColor: "rgba(132,132,132,0.8)",
              borderWidth: 0,
              borderRadius: wpd(0.5),
              marginTop: hpd(2),
              width: wpd(10),
            }}
            items={items}
            itemSeparator={false}
            labelStyle={localStyles.buttonText}
            listItemLabelStyle={localStyles.button}
            listMode="FLATLIST"
            loading={false}
            multiple={false}
            open={open}
            placeholder={strings.navbar.navbar_know_us}
            placeholderStyle={[
              localStyles.buttonText,
              {
                color:
                  selectedBtn === "Our Services" || selectedBtn === "About Us"
                    ? Colors.burgundy
                    : Colors.white,
              },
            ]}
            seAfterSelecting={true}
            selectedItemLabelStyle={[
              localStyles.buttonText,
              { color: Colors.burgundy },
            ]}
            setItems={setItems}
            setOpen={setOpen}
            setValue={setValue}
            showArrowIcon={false}
            showTickIcon={false}
            textStyle={localStyles.buttonText}
            value={null}
          />
        </View>
        <TouchableOpacity
          onPress={(event) => {
            event.preventDefault();
            setOpen(false);
            setSelectedBtn("University Selection");
            goTo("University Selection");
          }}
          accessibilityRole="link"
          href="/uniselection"
          style={localStyles.button}
        >
          <Text
            style={[
              localStyles.buttonText,
              {
                color:
                  (selectedBtn === "Major Home Page" && isUni == true) ||
                  selectedBtn === "University Selection" ||
                  selectedBtn === "University Home Page" ||
                  (selectedBtn === "Majors Selection" && isUni == true)
                    ? Colors.burgundy
                    : Colors.white,
              },
            ]}
          >
            {strings.navbar.navbar_universities}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={(event) => {
            event.preventDefault();
            setOpen(false);
            setSelectedBtn("Majors Selection");
            goTo("Majors Selection");
            setIsUni(false);
          }}
          style={localStyles.button}
          accessibilityRole="link"
          href="/majorSelection"
        >
          <Text
            style={[
              localStyles.buttonText,
              {
                color:
                  (selectedBtn === "Majors Selection" && isUni == false) ||
                  (selectedBtn === "Major Home Page" && isUni == false)
                    ? Colors.burgundy
                    : Colors.white,
              },
            ]}
          >
            {strings.navbar.navbar_majors}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={localStyles.button}
          onPress={(event) => {
            event.preventDefault();
            setSelectedBtn("Favourites");
            goTo("Favourite");
          }}
          accessibilityRole="link"
          href="/favourites"
        >
          <Text
            style={[
              localStyles.buttonText,
              {
                color:
                  selectedBtn === "Favourite" ? Colors.burgundy : Colors.white,
              },
            ]}
          >
            {strings.navbar.navbar_favourites}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={(event) => {
            event.preventDefault();
            setSelectedBtn("Feedback");
            goTo("Feedback");
          }}
          accessibilityRole="link"
          href="/feedback"
          style={localStyles.button}
        >
          <Text
            style={[
              localStyles.buttonText,
              {
                color:
                  selectedBtn === "Feedback" ? Colors.burgundy : Colors.white,
              },
            ]}
          >
            {strings.navbar.navbar_contact_us}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
const localStyles = StyleSheet.create({
  mainView: {
    alignItems: "center",
    backgroundColor: "rgba(132,132,132,0.8)",
    // opacity: 0.8,
    ...Platform.select({
      web: {
        position: "fixed",
      },
    }),
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,

    //height: Dimensions.get("window").height * 0.1,
    //width: Dimensions.get("window").width,
  },
  button: {
    alignContent: "center",
    alignItems: "center",
    paddingHorizontal: wpd(2.5),
    paddingVertical: hpd(1),
  },
  buttonText: {
    color: Colors.white,
    fontFamily: "Cairo",
    fontSize: wpd(webTextPercent),
    textAlign: "center",
  },
});
