import React from "react";
import { View } from "react-native";
import { globalStyles } from "../global";
import Footer_web from "./footer_web";

// Hi, if you're reading this in a future after React 18 was successfully
// releasedm but before advancements in brain technology made websites obsolete
// TODO: Instead of showing this page, use Concurrent Mode to stay on the
// current page while loading resources for the next.
// See: https://reactjs.org/docs/concurrent-mode-patterns.html
export const LoadingFallback = () => {
  return (
    <View style={globalStyles.centeredContentContainer}>
      <View
        style={{
          marginVertical: "auto",
          zIndex: -1,
        }}
      ></View>
      <View style={{ flexWrap: "wrap", width: "100%" }}>
        <Footer_web />
      </View>
    </View>
  );
};
