import { AntDesign, Entypo, FontAwesome } from "@expo/vector-icons";
import React from "react";
import {
  Dimensions,
  Image,
  ImageBackground,
  Linking,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import strings from "./../assets/strings/hardCodedStrings.json";
import { cairoBoldWeight, Colors, Typography } from "./../global";

const { width, height } = Dimensions.get("window");

export default function mobileBrowserScreen({ route }) {
  return (
    <ImageBackground
      resizeMode="cover"
      style={{ height: "100%", width: "100%" }}
      source={require("../assets/cliparts/background-02.webp")}
    >
      <View
        style={[
          {
            height: "100%",
            justifyContent: "center",
            padding: 25,
          },
        ]}
      >
        <Image
          source={require("./../assets/coloredLogo.webp")}
          style={styles.image}
        />
        <Text
          style={[
            Typography.arabicText,
            styles.text,
            { paddingVertical: 20, fontWeight: cairoBoldWeight },
          ]}
        >
          {strings.mobile_browser.h1}
        </Text>
        <Text
          style={[
            Typography.arabicText,
            styles.text,
            { paddingVertical: 20, fontWeight: cairoBoldWeight },
          ]}
        >
          {strings.mobile_browser.h2}
        </Text>
        <Text style={[Typography.arabicText, styles.text]}>
          {strings.mobile_browser.h3}
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginVertical: 20,
          }}
        >
          <TouchableWithoutFeedback
            onPress={() =>
              Linking.openURL(
                "https://play.google.com/store/apps/details?id=com.dalelukllc.Daleluk"
              )
            }
          >
            <Image
              source={require("./../assets/playstore.png")}
              style={[styles.image, { width: width / 3, height: height / 18 }]}
            />
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback
            onPress={() =>
              Linking.openURL(
                "https://apps.apple.com/qa/app/daleluk-%D8%AF%D9%84%D9%8A%D9%84%D9%83/id1584852422"
              )
            }
          >
            <Image
              source={require("./../assets/appstore.svg")}
              style={[styles.image, { width: width / 3, height: height / 18 }]}
            />
          </TouchableWithoutFeedback>
        </View>
        <View style={[styles.row, styles.iconRow]}>
          <AntDesign
            name="twitter"
            size={24}
            color={Colors.burgundy}
            style={styles.icon}
            onPress={() => Linking.openURL(strings.about_us.link_twitter)}
          />
          <AntDesign
            name="instagram"
            size={24}
            color={Colors.burgundy}
            style={styles.icon}
            onPress={() => Linking.openURL(strings.about_us.link_instagram)}
          />
          <Entypo
            name="linkedin"
            size={24}
            color={Colors.burgundy}
            style={styles.icon}
            onPress={() => Linking.openURL(strings.about_us.link_linkedin)}
          />
          <FontAwesome
            name="whatsapp"
            size={24}
            color={Colors.burgundy}
            style={styles.icon}
            onPress={() => Linking.openURL(strings.about_us.link_whatsapp)}
          />
          <AntDesign
            name="youtube"
            size={24}
            color={Colors.burgundy}
            style={styles.icon}
            onPress={() => Linking.openURL(strings.about_us.link_youtube)}
          />
        </View>
        <Text
          style={[
            Typography.arabicText,
            styles.text,
            { color: Colors.burgundy },
          ]}
          onPress={() =>
            Linking.openURL("mailto:" + strings.about_us.contact_us_email)
          }
        >
          {strings.about_us.contact_us_email}
        </Text>
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  image: {
    width: "40%",
    height: height / 3,
    resizeMode: "contain",
    alignSelf: "center",
  },
  text: {
    fontSize: 18,
    color: Colors.black,
    textAlign: "center",
    lineHeight: 18 * 1.5,
  },
  row: {
    flexDirection: "row",
    // marginBottom: 40
  },
  iconRow: {
    marginVertical: 5,
    alignSelf: "center",
  },
  icon: {
    marginHorizontal: 10,
  },
});
