import { useNavigation } from "@react-navigation/core";
import React from "react";
import Markdown from "react-native-markdown-display";
import { globalMarkdownStyle } from "../global";
import { MarkdownLinkComponent } from "./MarkdownLinkComponent";

// Wrapper for react-native-markdown-display
// For full list of props see https://www.npmjs.com/package/react-native-markdown-display#props-and-functions
export const MarkdownText = ({ children, style, rules, ...props }) => {
  const navigation = useNavigation();
  const onLinkPress = (url) => {
    if (url) {
      if (url.startsWith("http")) {
        Linking.openURL(url);
      }
      navigation.navigate(decodeURI(url));

      return false;
    }
  };
  return (
    <Markdown
      style={{
        ...globalMarkdownStyle,
        ...style,
      }}
      rules={{
        link: MarkdownLinkComponent,
        ...rules,
      }}
      onLinkPress={onLinkPress}
      {...props}
    >
      {children}
    </Markdown>
  );
};
