import * as Updates from "expo-updates";

export default function getEnvironment() {
  if (Updates.releaseChannel.startsWith("prod")) {
    // matches words starting with 'prod', e.g., prod-v1, production-v2
    return {
      firebaseConfig: {
        // copy and paste here firebase config for prod
        apiKey: "AIzaSyBByAjVu89SJChSe8rsl01yNtBF5cLGzPs",
        authDomain: "daleluk-qa.firebaseapp.com",
        projectId: "daleluk-qa",
        storageBucket: "daleluk-qa.appspot.com",
        messagingSenderId: "500712487472",
        appId: "1:500712487472:web:10c37db2d9c461dbaccbf5",
        measurementId: "G-HH1NYSBLTZ",
      },
    };
    // } else if (Updates.releaseChannel.startsWith("staging")) {
    //   // matches words starting with 'staging', e.g., staging-v1
    //   return {
    //     firebaseConfig: {
    //       // copy and paste here firebase config for stage
    //     },
    //   };
    // } else if (Updates.releaseChannel.startsWith("dev")) {
    //   // matches words starting with 'dev', e.g., dev-v1
    //   return {
    //     firebaseConfig: {
    //       apiKey: "AIzaSyBV3AAW-S1L9hmdrD-nl75a2Mnzm7E_jEY",
    //       authDomain: "daleluk-qa-dev-56d22.firebaseapp.com",
    //       projectId: "daleluk-qa-dev-56d22",
    //       storageBucket: "daleluk-qa-dev-56d22.appspot.com",
    //       messagingSenderId: "304034306875",
    //       appId: "1:304034306875:web:50a05d177494fb64fd751e",
    //       measurementId: "G-GZ4BR41L43",
    //     },
    //   };
  } else {
    // assume any other release channel is development
    return {
      firebaseConfig: {
        apiKey: "AIzaSyBByAjVu89SJChSe8rsl01yNtBF5cLGzPs",
        authDomain: "daleluk-qa.firebaseapp.com",
        projectId: "daleluk-qa",
        storageBucket: "daleluk-qa.appspot.com",
        messagingSenderId: "500712487472",
        appId: "1:500712487472:web:10c37db2d9c461dbaccbf5",
        measurementId: "G-HH1NYSBLTZ",
      },
    };
  }
}
