import { getPathFromState } from "@react-navigation/native";

export const screenMapping = {
  Home: "",
  "Majors Selection": "majorSelection",
  Favourite: "favourites",
  Feedback: "feedback",
  "About Us": "aboutUs",
  "Main Filter": "mainFilter",
  "Payment Methods": "universities/payment/methods",
  "University Fee": "universities/payment/fee",
  "University Home Page": "universities/:tab",
  "University Selection": "uniSelection",
  "Certificate Selection": "certifications",
  "Our Services": "ourServices",
  "Financial Aid Details": "universities/financialAid/details",
  Deadlines: "universities/admissions/deadlines",
  Process: "universities/admissions/process",
  Outcome: "universities/admissions/outcome",
  "Academic Services": "universities/services/academic",
  "Non Academic Services": "universities/services/nonAcademic",
  "Facility Details": "universities/facilities/details",
  "Major Home Page": "majors",
};

export default {
  // TODO: Add Daleluk web app domain name
  prefixes: ["Daleluk://"],
  config: {
    screens: screenMapping,
  },
  // Removes [Object object] from URL when objects are passed as params
  // See https://stackoverflow.com/a/67071723
  getPathFromState: (state, options) => {
    const cleanState = {
      ...state,
      routes: state.routes.map((route) => {
        if (!route.params) {
          return route;
        }

        const cleanParams = {};
        for (const param in route.params) {
          const value = route.params[param];
          if (typeof value !== "object" && typeof value !== "function") {
            cleanParams[param] = value;
          }
        }
        return {
          ...route,
          params: cleanParams,
        };
      }),
    };
    return getPathFromState(cleanState, options);
  },
};
