import React from "react";
import IcoMoon from "react-icomoon";
import { Path, Svg } from "react-native-svg";
const iconSet = require("../selection.json");

const Icon = ({ icon, size = 36, ...props }) => {
  return (
    <IcoMoon
      native
      iconSet={iconSet}
      SvgComponent={Svg}
      PathComponent={Path}
      icon={icon}
      size={size}
      {...props}
    />
  );
};

export default Icon;
