import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as React from "react";

export function useLoadedAssets() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const min_timeout = 2000; // milliseconds

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();
        await Font.loadAsync({
          FontAwesome: require("../assets/fonts/FontAwesome.ttf"),
        });
      } catch (e) {
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }
    const timeStarted = performance.now(); // returns milliseconds
    loadResourcesAndDataAsync();
    const timeElapsed = performance.now() - timeStarted;
    setTimeout(SplashScreen.hideAsync, Math.max(min_timeout - timeElapsed, 0));
  }, []);

  return isLoadingComplete;
}
