import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Platform } from "react-native";
import strings from "../assets/strings/hardCodedStrings.json";
import { Colors } from "../global";
import favouriteScreen from "../screens/favouriteScreen";
import linking from "./linking";

const Stack = createStackNavigator();

const uniHomePageTitles = {
  info: strings.titles.university_home_page,
  admissions: strings.titles.admissions,
  majors: strings.titles.majors,
  services: strings.titles.services,
  facilities: strings.titles.facilities,
  payment: strings.titles.payment,
  financialAid: strings.titles.financial_aid,
};

export default Navigator = ({
  navigationRef,
  handleNav = () => {},
  handleBack = () => {},
}) => {
  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      <Stack.Navigator
        initialRouteName="Home"
        screenOptions={{
          headerShown: Platform.OS == "ios",
          headerTintColor: Colors.burgundy,
          headerTitle: "",
          headerBackTitle: " ",
          headerTransparent: true,
        }}
        screenListeners={{
          state: handleNav,
          beforeRemove: handleBack,
        }}
      >
        <Stack.Screen
          name="Home"
          component={React.lazy(() => import("../screens/homeScreen"))}
          options={{ title: strings.titles.home_page }}
        />
        <Stack.Screen
          name="Majors Selection"
          component={React.lazy(() =>
            import("../screens/majorSelectionScreen")
          )}
          options={{ title: strings.titles.major_selection }}
        />
        <Stack.Screen
          name="Favourite"
          component={favouriteScreen}
          options={{ title: strings.titles.favorites }}
        />
        <Stack.Screen
          name="Feedback"
          component={React.lazy(() => import("../screens/feedbackScreen"))}
          options={{ title: strings.titles.feedback }}
        />
        <Stack.Screen
          name="About Us"
          component={React.lazy(() => import("../screens/aboutUsScreen"))}
          options={{ title: strings.titles.about_us }}
        />
        <Stack.Screen
          name="Main Filter"
          component={React.lazy(() => import("../screens/mainFilterScreen"))}
          options={{ title: strings.titles.main_filter }}
        />
        <Stack.Screen
          name="Payment Methods"
          component={React.lazy(() => import("../screens/paymentMethods"))}
          options={{ title: strings.titles.payment_methods }}
        />

        <Stack.Screen
          name="University Fee"
          component={React.lazy(() => import("../screens/universityFee"))}
          options={{ title: strings.titles.university_fee }}
        />
        <Stack.Screen
          name="University Home Page"
          component={React.lazy(() => import("../screens/universityHomePage"))}
          options={({ route }) => ({
            title: route?.params?.tab
              ? uniHomePageTitles[route.params.tab]
              : strings.titles.university_home_page,
          })}
        />
        <Stack.Screen
          name="University Selection"
          component={React.lazy(() =>
            import("../screens/universitiesSelectionScreen")
          )}
          options={{ title: strings.titles.university_selection }}
        />
        <Stack.Screen
          name="Certificate Selection"
          component={React.lazy(() =>
            import("../screens/certificateSelection")
          )}
          options={{ title: strings.titles.certification_selection }}
        />
        <Stack.Screen
          name="Our Services"
          component={React.lazy(() => import("../screens/ourServicesScreen"))}
          options={{ title: strings.titles.our_services }}
        />
        <Stack.Screen
          name="Financial Aid Details"
          component={React.lazy(() => import("../screens/financialAidDetails"))}
          options={{ title: strings.titles.financial_aid_details }}
        />
        <Stack.Screen
          name="Deadlines"
          component={React.lazy(() => import("../screens/deadlinesScreen"))}
          options={{ title: strings.titles.deadlines }}
        />
        <Stack.Screen
          name="Process"
          component={React.lazy(() => import("../screens/processScreen"))}
          options={{ title: strings.titles.process }}
        />
        <Stack.Screen
          name="Outcome"
          component={React.lazy(() => import("../screens/outcomeScreen"))}
          options={{ title: strings.titles.outcome }}
        />
        <Stack.Screen
          name="Academic Services"
          component={React.lazy(() => import("../screens/acadServicesScreen"))}
          options={{ title: strings.titles.academic_services }}
        />
        <Stack.Screen
          name="Non Academic Services"
          component={React.lazy(() =>
            import("../screens/nonAcadServicesScreen")
          )}
          options={{ title: strings.titles.non_academic_services }}
        />
        <Stack.Screen
          name="Facility Details"
          component={React.lazy(() =>
            import("../screens/facilityDetailsScreen")
          )}
          options={{ title: strings.titles.facility_details }}
        />
        <Stack.Screen
          name="Major Home Page"
          component={React.lazy(() => import("../screens/majorHomePageScreen"))}
          options={{ title: strings.titles.major_home_page }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
