import { Platform, StyleSheet } from "react-native";
import {
  heightPercentageToDP as hpd, widthPercentageToDP as wpd
} from "react-native-responsive-screen";

export const Colors = {
  burgundy: "#700016",
  burgundyHighlight: "#4f0010",
  burgundyRipple: "#3f0000",
  grey: "#848484",
  greyHighlight: "#696969",
  greyRipple: "#505050",
  lightGrey: "#C4C2C2",
  white: "#fff",
  black: "#000",
};
export const topPadding = Platform.OS == "web" ? hpd(5) : 75;
export const bottomPadding = Platform.OS == "web" ? hpd(5) : 150;
export const bottomPaddingScrollView = Platform.OS == "web" ? hpd(5) : 150;
export const horizontalPadding = Platform.OS == "web" ? wpd(15) : 30;
export const bannerHeight = hpd(50);

// web font sizes; web uses responsive font sizes;
export const webTitlePercent = 2.4;
export const webSubTitlePercent = 1.4;
export const webTextPercent = 1.1;
export const webSubTextPercent = 0.9;
// Android font sizes;
export const androidTitle = 22;
export const androidSubTitle = 18;
export const androidText = 14;
export const androidSubText = 12;
// iOS font sizes;
export const iOSTitle = 22;
export const iOSSubTitle = 18;
export const iOSText = 14;
export const iOSSubText = 12;

export const textLineSpacingMultiplier = Platform.OS == "web" ? 2 : 1.5;

export const cairoBoldWeight = 700;
export const cairoExtraBoldWeight = 900;

export const Typography = StyleSheet.create({
  title: {
    color: Colors.burgundy,
    fontFamily: "CairoBold",
    ...Platform.select({
      android: {
        fontSize: androidTitle,
        paddingBottom: androidTitle,
      },
      ios: {
        fontSize: iOSTitle,
        paddingBottom: iOSTitle,
      },
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
        fontSize: wpd(webTitlePercent),
        paddingBottom: hpd(webTitlePercent),
      },
    }),
    textAlign: "left",
    writingDirection: "rtl",
  },
  titlePadded: {
    color: Colors.burgundy,
    fontFamily: "CairoBold",
    ...Platform.select({
      android: {
        fontSize: androidTitle,
        paddingBottom: androidTitle,
      },
      ios: {
        fontSize: iOSTitle,
        paddingBottom: iOSTitle,
      },
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
        fontSize: wpd(webTitlePercent),
        paddingBottom: hpd(webTitlePercent),
      },
    }),
    paddingHorizontal: horizontalPadding,
    textAlign: "left",
    writingDirection: "rtl",
  },
  webTitleCenteredBottomOverBanner: {
    fontFamily: "CairoBold",
    fontSize: Platform.OS == "web" ? wpd(webTitlePercent) : 26,
    textAlign: "left",
    writingDirection: "rtl",
    alignSelf: "center",
    color: Colors.white,
    marginTop: bannerHeight - wpd(webTitlePercent) * 2.5,
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    // paddingHorizontal: wpd(1),
    ...Platform.select({
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
      },
    }),
  },
  subTitle: {
    color: Colors.black,
    fontFamily: "CairoBold",
    ...Platform.select({
      android: {
        fontSize: androidSubTitle,
        paddingBottom: androidSubTitle,
      },
      ios: {
        fontSize: iOSSubTitle,
        paddingBottom: iOSSubTitle,
      },
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
        fontSize: wpd(webSubTitlePercent),
        paddingBottom: hpd(webSubTitlePercent),
      },
    }),
    textAlign: "left",
    writingDirection: "rtl",
  },
  arabicText: {
    color: Colors.black,
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidText,
        lineHeight: androidText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSText,
        lineHeight: iOSText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webTextPercent),
        lineHeight: wpd(webTextPercent) * textLineSpacingMultiplier,
        whiteSpace: "unset",
      },
    }),
    textAlign: "justify",

    writingDirection: "rtl",
  },
  paddedArabicText: {
    color: Colors.black,
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidText,
        lineHeight: androidText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSText,
        lineHeight: iOSText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webTextPercent),
        lineHeight: wpd(webTextPercent) * textLineSpacingMultiplier,
      },
    }),
    paddingHorizontal: horizontalPadding,
    textAlign: "justify",
    writingDirection: "rtl",
  },
  descriptionText: {
    alignSelf: "center",
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidText,
        lineHeight: androidText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSText,
        lineHeight: iOSText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webTextPercent),
        lineHeight: wpd(webTextPercent) * textLineSpacingMultiplier,
        whiteSpace: "unset",
      },
    }),
    textAlign: "justify",

    writingDirection: "rtl",
  },
  paddedDescriptionText: {
    alignSelf: "center",
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidText,
        lineHeight: androidText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSText,
        lineHeight: iOSText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webTextPercent),
        lineHeight: wpd(webTextPercent) * textLineSpacingMultiplier,
      },
    }),
    paddingHorizontal: horizontalPadding,
    textAlign: "justify",
    writingDirection: "rtl",
  },
  centeredText: {
    color: Colors.white,
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidText,
        lineHeight: androidText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSText,
        lineHeight: iOSText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webTextPercent),
        lineHeight: wpd(webTextPercent) * textLineSpacingMultiplier,
      },
    }),
    textAlign: "center",
    writingDirection: "rtl",
  },
  centeredErrorText: {
    color: Colors.lightGrey,
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidSubText,
        lineHeight: androidSubText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSSubText,
        lineHeight: iOSSubText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webSubTextPercent),
        lineHeight: wpd(webSubTextPercent) * textLineSpacingMultiplier,
      },
    }),
    textAlign: "center",
    textAlignVertical: "center",
    width: "80%",
    writingDirection: "rtl",
  },
  months: {
    fontFamily: "Aref Ruqaa",
    writingDirection: "rtl",
  },
  numbers: {
    fontFamily: "CairoBold",
    writingDirection: "rtl",
    ...Platform.select({
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
      },
    }),
  },
  imageText: {
    color: Colors.white,
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    // paddingHorizontal: wpd(1),
    writingDirection: "rtl",
  },
});

export const globalStyles = StyleSheet.create({
  //////////////////////////Please use these containers in all the screens////////////////////
  scrollViewContainer: {
    paddingTop: topPadding,
    paddingBottom: bottomPaddingScrollView,
    paddingHorizontal: horizontalPadding,
    backgroundColor: Colors.white,
  },
  scrollViewEdge2EdgeContainer: {
    paddingTop: topPadding,
    paddingBottom: bottomPaddingScrollView,
    backgroundColor: Colors.white,
  },
  staticContentContainer: {
    paddingTop: topPadding,
    paddingBottom: bottomPadding,
    paddingHorizontal: horizontalPadding,
    backgroundColor: Colors.white,
    height: "100%",
  },
  centeredContentContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white,
    height: "100%",
  },
  centeredContentContainerWeb: {
    paddingTop: hpd(2.1) + wpd(2),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white,
    height: "100%",
  },
  scrollViewPlusStaticContentContainerWeb: {
    paddingTop: hpd(2.1) + wpd(2) + topPadding,
    paddingHorizontal: horizontalPadding,
    paddingBottom: topPadding,
    backgroundColor: Colors.white,
  },
  secondarySscrollViewContainerWeb: {
    paddingHorizontal: horizontalPadding,
    paddingTop: topPadding / 2,
    paddingBottom: bottomPadding,
  },

  ////////////////////////////////////////////////////////////////////////////////////////

  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white,
    paddingTop: 75,
    paddingHorizontal: "5%",
  },
  screenContainer: {
    padding: 30,
    backgroundColor: Colors.white,
    height: "100%",
    ...Platform.select({
      web: {
        backgroundColor: Colors.white,
      },
      default: {
        paddingTop: 75,
        paddingBottom: 150,
      },
    }),
  },
  roundButton: {
    // marginTop: 50,
    // marginBottom: 20,
    // flex: 1,
    width: 100,
    height: 100,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderRadius: 50,
    borderColor: "black",
    borderWidth: 5,
  },
  buttonImageIcon: {
    // padding: 10,
    resizeMode: "stretch",
  },
  textCenter: {
    textAlign: "center",
  },
  clipartImage: {
    width: "100%",
    height: undefined,
    resizeMode: "contain",
    aspectRatio: 1.5 / 1,
    ...Platform.select({
      web: {
        height: "100%",
        width: "40%",
      },
    }),
  },
  webBanner: {
    zIndex: -1,

    width: wpd(100),
    height: bannerHeight,
  },
  squareClipartImage: {
    width: "60%",
    height: undefined,
    aspectRatio: 1,
    alignSelf: "center",
    resizeMode: "contain",
    ...Platform.select({
      web: {
        width: "100%",
        height: "100%",
      },
      default: { marginVertical: hpd(5) },
    }),
  },
  moreInfo: {
    position: "absolute",
    right: 18,
    bottom: 4,
    alignSelf: "flex-end",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  dropShadow: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 6,
  },
});

export const globalMarkdownStyle = StyleSheet.create({
  body: { writingDirection: "rtl" },
  heading1: {
    fontFamily: "CairoBold",
    color: Colors.burgundy,
    ...Platform.select({
      android: {
        fontSize: androidTitle,
        paddingBottom: androidTitle,
      },
      ios: {
        fontSize: iOSTitle,
        paddingBottom: iOSTitle,
      },
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
        fontSize: wpd(webTitlePercent),
        paddingBottom: hpd(webTitlePercent),
      },
    }),
  },
  heading2: {
    fontFamily: "CairoBold",
    ...Platform.select({
      android: {
        fontSize: androidSubTitle,
        paddingBottom: androidSubTitle,
      },
      ios: {
        fontSize: iOSSubTitle,
        paddingBottom: iOSSubTitle,
      },
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
        fontSize: wpd(webSubTitlePercent),
        paddingBottom: hpd(webSubTitlePercent),
      },
    }),
  },
  heading3: {
    fontFamily: "CairoExtraBold",
    ...Platform.select({
      android: {
        fontSize: androidText,
      },
      ios: {
        fontSize: iOSText,
      },
      web: {
        fontSize: wpd(webTextPercent),
        fontFamily: "Cairo",
        fontWeight: cairoExtraBoldWeight,
      },
    }),
  },
  strong: {
    fontFamily: "CairoBold",
    fontWeight: "normal",
    ...Platform.select({
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
      },
    }),
  },
  textgroup: {
    textAlign: "justify",
    ...(Platform.OS === "web" && { whiteSpace: "unset" }),
  },

  paragraph: {
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidText,
        lineHeight: androidText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSText,
        lineHeight: iOSText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webTextPercent),
        lineHeight: wpd(webTextPercent) * textLineSpacingMultiplier,
      },
    }),
  },
  bullet_list: {
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidText,
        lineHeight: androidText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSText,
        lineHeight: iOSText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webTextPercent),
        lineHeight: wpd(webTextPercent) * textLineSpacingMultiplier,
      },
    }),
  },
  table: {
    borderWidth: 0,
  },
  thead: {
    fontFamily: "CairoBold",
    fontWeight: "normal",
    ...Platform.select({
      web: {
        fontFamily: "Cairo",
        fontWeight: cairoBoldWeight,
      },
    }),
  },
  tbody: {
    fontFamily: "Cairo",
    ...Platform.select({
      android: {
        fontSize: androidText,
        lineHeight: androidText * textLineSpacingMultiplier,
      },
      ios: {
        fontSize: iOSText,
        lineHeight: iOSText * textLineSpacingMultiplier,
      },
      web: {
        fontSize: wpd(webTextPercent),
        lineHeight: wpd(webTextPercent) * textLineSpacingMultiplier,
      },
    }),
  },
  th: {
    borderColor: Colors.burgundy,
    borderBottomWidth: 1,
  },
  tr: { borderBottomWidth: 1, borderColor: Colors.burgundy },
  blockquote: {
    backgroundColor: "#F5F5F5",
    borderColor: Colors.burgundy,
  },
  hr: {
    backgroundColor: Colors.burgundy,
    height: 2,
  },
});

export const burgundy_screen = StyleSheet.create({
  upper_half: {
    flex: 5,
  },
  lower_half: {
    flex: 3,
    backgroundColor: Colors.burgundy,
    borderTopLeftRadius: 120,
    borderTopRightRadius: 120,
  },
});
