import { AntDesign, Entypo, FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from "react-native-responsive-screen";
import strings from "../assets/strings/hardCodedStrings.json";
import analytics from "../ga";
import { Colors, webSubTextPercent } from "../global";

const socialMediaIconSize = widthPercentageToDP(1.5);

export default function Footer_web() {
  if (Platform.OS !== "web") return null;
  return (
    <View style={localStyles.mainView}>
      <View style={[localStyles.socialMediaIconsView]}>
        <AntDesign
          name="twitter"
          size={socialMediaIconSize}
          color={Colors.white}
          style={localStyles.icons}
          accessibilityRole="link"
          href={strings.about_us.link_twitter}
        />
        <AntDesign
          name="instagram"
          size={socialMediaIconSize}
          color={Colors.white}
          style={localStyles.icons}
          onPress={() => {
            analytics.logEvent("button_pressed_social_media", {
              social_media_name: "Twitter",
            });
          }}
          accessibilityRole="link"
          href={strings.about_us.link_instagram}
        />
        <Entypo
          name="linkedin"
          size={socialMediaIconSize}
          color={Colors.white}
          style={localStyles.icons}
          onPress={() => {
            analytics.logEvent("button_pressed_social_media", {
              social_media_name: "Likedin",
            });
          }}
          accessibilityRole="link"
          href={strings.about_us.link_linkedin}
        />
        <FontAwesome
          name="whatsapp"
          size={socialMediaIconSize}
          color={Colors.white}
          style={localStyles.icons}
          onPress={() => {
            analytics.logEvent("button_pressed_social_media", {
              social_media_name: "Instagram",
            });
          }}
          accessibilityRole="link"
          href={strings.about_us.link_whatsapp}
        />
        <AntDesign
          name="youtube"
          size={socialMediaIconSize}
          color={Colors.white}
          style={localStyles.icons}
          onPress={() => {
            analytics.logEvent("button_pressed_social_media", {
              social_media_name: "YouTube",
            });
          }}
          accessibilityRole="link"
          href={strings.about_us.link_youtube}
        />
      </View>
      <View style={localStyles.emailView}>
        <Text
          style={localStyles.email}
          accessibilityRole="link"
          href={"mailto:" + strings.footer.footer_email}
        >
          {strings.footer.footer_email}
        </Text>
        <Text style={localStyles.year}>
          {"  |  "}
          {strings.footer.footer_year}
        </Text>
      </View>
    </View>
  );
}
const localStyles = StyleSheet.create({
  mainView: {
    backgroundColor: Colors.burgundy,
    flex: 1,
    flexDirection: "row",
  },
  socialMediaIconsView: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginStart: widthPercentageToDP(8),
    paddingVertical: heightPercentageToDP(1),
  },
  emailView: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    marginEnd: widthPercentageToDP(8),
    paddingVertical: heightPercentageToDP(1),
  },
  icons: {
    paddingHorizontal: widthPercentageToDP(0.5),
  },
  email: {
    color: Colors.white,
    fontSize: widthPercentageToDP(webSubTextPercent),
    textAlignVertical: "center",
  },
  year: {
    color: Colors.white,
    fontFamily: "Cairo",
    fontSize: widthPercentageToDP(webSubTextPercent),
    textAlignVertical: "center",
  },
});
