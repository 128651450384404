import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import { widthPercentageToDP as wpd } from "react-native-responsive-screen";
import { AntDesign, Entypo, FontAwesome } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";
import analytics from "../ga";
import {
  globalStyles,
  Typography,
  Colors,
  androidSubText,
  iOSSubText,
  cairoBoldWeight,
} from "../global";
import ExpandableCardIcon from "./ExpandableCardIcon";
import strings from "../assets/strings/hardCodedStrings.json";
import { widthPercentageToDP } from "react-native-responsive-screen";
import { MarkdownText } from "./MarkdownText";

const moreInfoSize =
  Platform.OS == "web"
    ? widthPercentageToDP(0.9)
    : Platform.OS == "android"
    ? androidSubText
    : iOSSubText;
const boxContainerPadding = Platform.OS == "web" ? widthPercentageToDP(2) : 20;
const boxContainerRadius = Platform.OS == "web" ? widthPercentageToDP(2) : 30;
const socialIconSize = Platform.OS == "web" ? widthPercentageToDP(2) : 24;
const exclamationSize = Platform.OS == "web" ? widthPercentageToDP(0.9) : 12;

export default function ExpandableCard({
  showFavorite,
  certificate,
  university,
  major,
}) {
  const [favorited, setFavorited] = useState(false);
  const [expanded, setExpanded] = useState(false);
  console.log("university", university);
  console.log("major", major);

  useFocusEffect(() => {
    (async () => {
      setFavorited(await isFavorited(major));
    })();
  });

  const isFavorited = async (major) => {
    if (major) {
      const jsonValue = await AsyncStorage?.getItem("@dlk_favorites");
      const favorites = jsonValue != null ? JSON.parse(jsonValue) : [];
      const foundUni = favorites.find((u) => u.id == major.parent_id);
      return foundUni && foundUni.majors.some((m) => m.id == major.id);
    }
    return false;
  };

  const addToFavorite = async (major) => {
    if (major) {
      setFavorited(true);
      const jsonValue = await AsyncStorage?.getItem("@dlk_favorites");
      const favorites = jsonValue != null ? JSON.parse(jsonValue) : [];
      console.log("fav", favorites);
      const foundUni = favorites.find((u) => u.id == major.parent_id);
      if (foundUni) {
        foundUni.majors.push(major);
      } else {
        const newUni = {
          id: major.parent_id,
          name: major.parent_name,
          majors: [major],
        };
        favorites.push(newUni);
      }
      if (Platform.OS == "web") {
        analytics.logEvent("major_favorited", {
          major_name: major.display_name,
          university_name: major.parent_name,
        });
      } else {
        analytics().logEvent("major_favorited", {
          major_name: major.display_name,
          university_name: major.parent_name,
        });
      }
      await AsyncStorage?.setItem("@dlk_favorites", JSON.stringify(favorites));
    }
  };

  const removeFromFavorite = async (major) => {
    if (major) {
      setFavorited(false);
      const jsonValue = await AsyncStorage?.getItem("@dlk_favorites");
      const favorites = JSON.parse(jsonValue);
      const foundUni = favorites.find((u) => u.id == major.parent_id);
      foundUni.majors = foundUni.majors.filter((m) => m.id != major.id);
      // If uni has no favorited majors, unfavorite uni
      if (foundUni.majors.length == 0) {
        const newFavorites = favorites.filter((uni) => uni.id != foundUni.id);
        await AsyncStorage?.setItem(
          "@dlk_favorites",
          JSON.stringify(newFavorites)
        );
      } else {
        await AsyncStorage?.setItem(
          "@dlk_favorites",
          JSON.stringify(favorites)
        );
      }
    }
  };

  return (
    <>
      <View style={localStyles.ExpandableRow}>
        {showFavorite && (
          <TouchableOpacity
            onPress={() =>
              favorited ? removeFromFavorite(major) : addToFavorite(major)
            }
            style={{ marginRight: "2%" }}
          >
            <Entypo
              name={favorited ? "heart" : "heart-outlined"}
              size={wpd(1.75)}
              color={Colors.burgundy}
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={() => setExpanded(!expanded)}
          style={{ marginRight: Platform.OS == "web" ? wpd(1) : "2%" }}
        >
          <AntDesign
            name={expanded ? "minuscircle" : "pluscircle"}
            size={wpd(1.75)}
            color={Colors.burgundy}
          />
        </TouchableOpacity>

        {certificate && (
          <Text style={Typography.arabicText}>
            {certificate.get("certification_name")}
          </Text>
        )}
        {university && (
          <Text style={Typography.arabicText}>{university.name}</Text>
        )}
        {major && (
          <Text style={Typography.arabicText}>{major.display_name}</Text>
        )}
      </View>
      {expanded && (
        <View
          style={[
            // Platform.OS == "web" && globalStyles.centeredContentContainerWeb,
            localStyles.expandedView,
          ]}
        >
          {certificate && (
            <>
              <View style={[localStyles.boxContainer, globalStyles.dropShadow]}>
                <ExpandableCardIcon
                  key={certificate.id}
                  certificate={certificate}
                />
                <View style={globalStyles.moreInfo}>
                  <Text
                    style={[Typography.arabicText, localStyles.moreInfoText]}
                  >
                    {strings.certification_selection.more_info}&nbsp;
                    <FontAwesome
                      name="exclamation-circle"
                      size={exclamationSize}
                      color="grey"
                    />
                  </Text>
                </View>
              </View>
              <MarkdownText
                style={{
                  textgroup: [Typography.arabicText],
                }}
              >
                {certificate.get("test_description")}
              </MarkdownText>
            </>
          )}
          {university &&
            university.majors.map((major, i) => (
              <ExpandableCard key={i} showFavorite={true} major={major} />
            ))}
          {major && (
            <View>
              <View style={[localStyles.boxContainer, globalStyles.dropShadow]}>
                <ExpandableCardIcon key={major.id} major={major} />
                <View style={globalStyles.moreInfo}>
                  <Text
                    style={[Typography.arabicText, localStyles.moreInfoText]}
                  >
                    {strings.certification_selection.more_info}&nbsp;
                    <FontAwesome
                      name="exclamation-circle"
                      size={exclamationSize}
                      color="grey"
                    />
                  </Text>
                </View>
              </View>
              <Text
                style={[
                  Typography.arabicText,
                  localStyles.majorDetails,
                  { fontFamily: "Cairo", fontWeight: cairoBoldWeight },
                ]}
              >
                {strings.major_home_page_all_universities.career_opportunities}
              </Text>
              <Text style={[Typography.arabicText, localStyles.majorDetails]}>
                {major.career_opportunities}
              </Text>
              <Text
                style={[
                  Typography.arabicText,
                  localStyles.majorDetails,
                  { fontFamily: "Cairo", fontWeight: cairoBoldWeight },
                ]}
              >
                {strings.major_home_page_all_universities.contact_college}
              </Text>
              <View>
                <View>
                  {major.college_location && (
                    <Text style={[Typography.arabicText]}>
                      {major.college_location}
                    </Text>
                  )}
                  {major.college_phone && (
                    <Text style={[Typography.arabicText]}>
                      {strings.major_home_page_all_universities
                        .contact_college_phone + ": "}
                      <Text
                        accessibilityRole="link"
                        href={"tel:" + major.college_phone}
                        onPress={() =>
                          Platform.OS != "web" &&
                          Linking.openURL("tel:" + major.college_phone)
                        }
                      >
                        {major.college_phone}
                      </Text>
                    </Text>
                  )}
                  {major.college_email && (
                    <Text style={[Typography.arabicText]}>
                      {strings.major_home_page_all_universities
                        .contact_college_email + ": "}
                      <Text
                        accessibilityRole="link"
                        href={"mailto:" + major.college_email}
                        onPress={() =>
                          Platform.OS != "web" &&
                          Linking.openURL("mailto:" + major.college_email)
                        }
                      >
                        {major.college_email}
                      </Text>
                    </Text>
                  )}
                </View>

                <View style={{ flexDirection: "row" }}>
                  {major.college_twitter && (
                    <TouchableOpacity
                      style={localStyles.contactCollegeLeft}
                      accessibilityRole="link"
                      href={"https://twitter.com/" + major.college_twitter}
                      onPress={() =>
                        Platform.OS != "web" &&
                        Linking.openURL(
                          `https://twitter.com/${major.college_twitter.replace(
                            "@",
                            ""
                          )}`
                        )
                      }
                    >
                      <AntDesign
                        name="twitter"
                        size={socialIconSize}
                        color={Colors.burgundy}
                      />
                    </TouchableOpacity>
                  )}
                  {major.college_facebook && (
                    <TouchableOpacity
                      style={localStyles.contactCollegeLeft}
                      accessibilityRole="link"
                      href={
                        "https://facebook.com/" +
                        major.college_facebook.replace("@", "")
                      }
                      onPress={() =>
                        Platform.OS != "web" &&
                        Linking.openURL(
                          `https://facebook.com/${major.college_facebook.replace(
                            "@",
                            ""
                          )}`
                        )
                      }
                    >
                      <FontAwesome
                        name="facebook"
                        size={socialIconSize}
                        color={Colors.burgundy}
                      />
                    </TouchableOpacity>
                  )}

                  {major.college_instagram && (
                    <TouchableOpacity
                      style={localStyles.contactCollegeLeft}
                      accessibilityRole="link"
                      href={
                        "https://instagram.com/" +
                        major.college_instagram.replace("@", "")
                      }
                      onPress={() =>
                        Platform.OS != "web" &&
                        Linking.openURL(
                          `https://instagram.com/${major.college_instagram.replace(
                            "@",
                            ""
                          )}`
                        )
                      }
                    >
                      <AntDesign
                        name="instagram"
                        size={socialIconSize}
                        color={Colors.burgundy}
                      />
                    </TouchableOpacity>
                  )}
                  {major.college_youtube && (
                    <TouchableOpacity
                      style={localStyles.contactCollegeLeft}
                      accessibilityRole="link"
                      href={major.college_youtube.match(/\(([^)]+)\)/)[1]}
                      onPress={() =>
                        Platform.OS != "web" &&
                        Linking.openURL(
                          `${major.college_youtube.match(/\(([^)]+)\)/)[1]}`
                        )
                      }
                    >
                      <AntDesign
                        name="youtube"
                        size={socialIconSize}
                        color={Colors.burgundy}
                      />
                    </TouchableOpacity>
                  )}
                </View>
                {Platform.OS == "web" && <View style={{ flex: 1 }}></View>}
              </View>
            </View>
          )}
        </View>
      )}
    </>
  );
}

const localStyles = StyleSheet.create({
  ExpandableRow: {
    flexDirection: "row",
    alignItems: "center",
    textAlignVertical: "center",
    margin: Platform.OS != "web" ? "2%" : 10,
  },
  boxContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignSelf: "flex-start",
    paddingTop: boxContainerPadding,
    paddingBottom: boxContainerPadding,
    backgroundColor: Colors.white,
    borderRadius: boxContainerRadius,
    ...Platform.select({
      web: {
        width: "100%",
        paddingHorizontal: wpd(1),
        alignSelf: "stretch",
      },
    }),
  },
  majorDetails: {
    marginTop: wpd(1),
  },
  contactCollegeLeft: {
    flexDirection: "row",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
    margin: Platform.OS != "web" ? wpd(2) : wpd(0.3),
  },
  socialIcons: {
    height: wpd(2),
    width: wpd(2),
    color: Colors.burgundy,
  },
  expandedView: {
    paddingBottom: wpd(1),
  },
  moreInfoText: {
    fontSize: moreInfoSize,
  },
});
