import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/functions";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/performance";
import getEnvironment from "./fb-config";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = getEnvironment().firebaseConfig;

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
  // Initialize Performance Monitoring and get a reference to the service
  const perf = firebase.performance();
}

export default firebase;
