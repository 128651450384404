import React from "react";
import { Linking, Platform, Text } from "react-native";
import { screenMapping } from "../navigation/linking";

// Component used to override links rendered by react-native-markdown-display
// Use on MD rendered on WEB to make links behave like anchor tags
export const MarkdownLinkComponent = (
  node,
  children,
  parent,
  styles,
  onLinkPress
) => {
  const hostname = Platform.OS == "web" ? `//${window.location.host}/` : "";

  const handleNavigation = (url) => {
    if (
      url.startsWith("http") ||
      url.startsWith("mailto:") ||
      url.startsWith("tel:")
    )
      Linking.openURL(node.attributes.href);
    else onLinkPress(url);
  };

  const urlToShow = (url) => {
    if (
      url.startsWith("http") ||
      url.startsWith("mailto:") ||
      url.startsWith("tel:")
    ) {
      return url;
    } else {
      return hostname + screenMapping[decodeURI(url)];
    }
  };
  return (
    <Text
      key={node.key}
      style={styles.link}
      onPress={(event) => {
        if (!event?.ctrlKey) {
          event.preventDefault();
          handleNavigation(node.attributes.href);
        }
      }}
      accessibilityRole="link"
      href={urlToShow(node.attributes.href)}
    >
      {children}
    </Text>
  );
};
