import AsyncStorage from "@react-native-async-storage/async-storage";
import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import { Platform, ScrollView, Text, View } from "react-native";
import strings from "../assets/strings/hardCodedStrings.json";
import ExpandableCard from "../components/ExpandableCard";
import Footer_web from "../components/footer_web";
import Icon from "../components/Icon";
import analytics from "../ga";
import { Colors, globalStyles, Typography } from "../global";

// @dlk_favorites mirrors the data shown on the favorites screen.
// [
//   (university) {
//     id,
//     name,
//     majors: [
//      {
//       id,
//       ...otherMajorFields,
//      }
//    ]
//   }
// ]

export default function favouriteScreen({ navigation }) {
  const [favoriteUnis, setFavoriteUnis] = useState([]);
  const isWeb = Platform.OS == "web";

  //using focus listener so it reloads favorites when coming "back"
  useEffect(
    () =>
      navigation.addListener("focus", async () => {
        const jsonValue = await AsyncStorage?.getItem("@dlk_favorites");
        const favorites = jsonValue != null ? JSON.parse(jsonValue) : [];
        setFavoriteUnis(favorites);
      }),
    [navigation]
  );
  if (favoriteUnis && favoriteUnis.length > 0) {
    favoriteUnis.map((uni) => {
      uni.majors.map((major) => {
        // TODO: Move logEvent to expandableCard
        analytics.logEvent("major_favorited", {
          major_name: major.display_name,
          university_name: uni.name,
        });
      });
    });
  }
  return favoriteUnis && favoriteUnis.length > 0 ? (
    <ScrollView
      contentContainerStyle={{
        backgroundColor: Colors.white,
        minHeight: "100%",
      }}
    >
      <StatusBar style={"dark"} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={
          isWeb
            ? globalStyles.scrollViewPlusStaticContentContainerWeb
            : globalStyles.scrollViewContainer
        }
      >
        <Text style={Typography.title}>{strings.favourites.main_title}</Text>

        {favoriteUnis.map((uni) => (
          <ExpandableCard key={uni.id} showFavorite={false} university={uni} />
        ))}
      </ScrollView>
      <View style={{ flexWrap: "wrap", width: "100%", marginTop: "auto" }}>
        <Footer_web />
      </View>
    </ScrollView>
  ) : (
    <View
      style={
        isWeb
          ? globalStyles.centeredContentContainerWeb
          : globalStyles.centeredContentContainer
      }
    >
      <View
        style={{
          marginVertical: "auto",
          alignItems: "center",
        }}
      >
        <Icon icon="favourites_empty_broken_heart" size={25} />
        <Text style={[Typography.centeredErrorText]}>
          {strings.favourites.no_favorites}
        </Text>
      </View>
      <View style={{ flexWrap: "wrap", width: "100%" }}>
        <Footer_web />
      </View>
    </View>
  );
}
